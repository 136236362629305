import { setModules } from "reactor/router/modules-list";

setModules([
  {
    path: '/',
    name: 'front',
    modules: [
      {
        entry: ['/menu'],
        module: 'menu',
      },
      // {
      //   entry: [
      //     "/login",
      //     "/register",
      //     "/forget-password",
      //     "/reset-password",
      //     "/account",
      //     "/checkout",
      //     "/payment",
      //     "/orders",
      //   ],
      //   module: "customers",
      // },
      {
        entry: ["/", "/about-us", "/contact-us", "/templates", "/jobs",
         "/single-work", "/stores", "/seo","/marketing", "/apps", "/schools",
         "/technicalsupport", 'vps', 'customservers', 'sharedhosting', "dedicatedservers", "cloudservers", "restaurants", "medical",
         "webdesign", "acadimies", "services", "works","rowaad-privacy", "rowaad-payments","rowaad-tos","/*"],
        module: "home",
      },
      {
        entry: ["/meals"],
        module: "meal",
      },
    ]
  }
]);