import React from 'react';
import './HelpSection.scss';
import Container from 'reactor/components/Grid/ContainerWithWidth'
import GridItem from 'reactor/components/Grid/GridItem';
import BoxItem from 'reactor/components/Box';
import Image from 'reactor/components/Image';
import Link from 'reactor/components/Link';
import Heading from '../../../components/heading';
import Support from 'assets/images/svgs/support.svg'
import Hidden from '@material-ui/core/Hidden';

export default function Help() {
    return (
        <div id="help__section">
            <Container justify="space-between" alignItems="center">
                <GridItem sm={6} xs={12}>
                    <BoxItem py={4} my={2} className="help__section--text">
                        <Heading
                            title="للتواصل والاستفسار"
                            subtitle="اذا كان لديك أي استفسار  عن أى خدمة من خدماتنا، لا تترد فى التواصل معنا."
                        />

                    </BoxItem>
                </GridItem>
                <GridItem md={6} xs={12}>
                    <BoxItem className="help__section--contact">
                        <div className='hidden-xs-sm' only="sm">
                            <Image src={Support} />
                        </div>
                        <Link to="/contact-us" className='contact-us'>تواصل الآن</Link>
                    </BoxItem>
                </GridItem>
            </Container>
        </div>
    )
}