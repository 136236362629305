import localization from 'reactor/localization';

localization.extend('ar', {
    appName: process.env.REACT_APP_NAME,
    dashboard: 'لوحة التحكم',
    users: 'المستخدمين',    
    orders: 'الطلبات',
    createdAt: 'تاريخ الإضافة',
    usersGroups: 'مجموعات المديرين',
    coupons: 'الكوبونات',
    categories: 'الأقسام',
    noResults: 'لا يوجد نتائج',
    customer: 'العميل',
    repliedBy: 'تم الرد بواسطة',
    orderTaxesValue: 'نسبة ضريبة الطلب',
    password: 'كلمة المرور',
    status: 'حالة الطلب',
    sizes: 'الأحجام',
    size: 'حجم',
    customers: 'العملاء',
    website: 'الموقع',
    totalQuantity: 'إجمالي الكمية',
    removeText: 'هل انت متأكد انك تريد حذف هذه البيانات؟',
    areYouSure: 'هل أنت متأكد؟',
    confirm: 'تأكيد',
    shippingFees: 'مصاريف التوصيل',
    branches: 'الفروع',
    published: 'متاح',
    yes: 'نعم',
    no: 'لا',
    email: 'البريد الاكتروني',
    districts: 'الأحياء',
    newsletter: 'القائمة البريدية',
    newsletterSingle: 'رسالة بريدية',
    subscriptions: 'الاشتراكات البريدية',
    subscription: 'إشتراك',
    payAtStore: 'الدفع في المطعم',
    campaigns: 'الحملات الاعلانية',
    campaign: 'حملة إعلانية',
    delivery: 'التوصيل إلى المنزل',
    title: 'العنوان',
    settings: 'الإعدادت',
    content: 'الوصف',
    pickup: 'الإستلام في المطعم',
    pending: 'في انتظار التاكيد من المطعم',
    adminCanceled: 'ملغي من قبل المطعم',
    processing: 'قبول الطلب و جاري التجهيز',
    onTheWay: 'في الطريق مسافه السكه',
    completed: 'تم التوصيل',
    returned: 'مرتجع',
    wallet: 'المحفظة الاكترونية',
    partiallyReturned: 'إرجاع جزئي',
    deposit: 'إيداع',
    withdraw: 'خصم',
    transactionType: 'نوع العملية',
    customerId: 'رقم العميل',
    pages: 'الصفحات',
    page: 'صفحة',
    notes: 'ملاحظات',
    canceled: 'ملغي', 
    balance: 'المبلغ',
    orderId: 'رقم الطلب',
    controlWallet: 'التحكم بالمحفظة الاكترونية',
    canceledItem: 'مرتجع',
    pickedUp: 'تم الإستلام',
    canceled: 'ملغي',
    cancel: 'رجوع',
    reason: 'السبب',
    waitingToPickup: 'إنتظار إستلام الطلب من المطعم',
    cashOnDelivery: 'الدفع عند الإستلام',
    publish: 'متاح',
    meals: 'الوجبات',
    name: 'الاسم',
    icon: 'الايقونة',
    image: 'الصورة',
    add: 'إضافة',
    loading: 'جاري التحميل....',
    edit: 'تعديل',
    remove: 'حذف',
    listItems: 'عرض %s',
    addItem: 'إضافة %s جديد',
    editItem: 'تعديل %s',
    viewItem: 'عرض %s',
    removeItem: 'حذف %s',
    actions: 'التحكم',
    en: 'English',
    accessDenied: 'غير مصرح لك بالدخول لهذه الصفحة',
    ar: 'العربية',
    refresh: 'إعادة تحميل',
    save: 'حفظ',
    view: 'عرض',
    phoneNumber: 'رقم الهاتف',
    // other app translations 
    order: 'طلب',
    category: 'ٌقسم',
    user: 'مستخدم',
    usersGroup: 'مجموعة مديرين',
    coupon: 'كوبون',
    meal: 'وجبة',
    deliveryMen: 'مندوبين التوصيل',
    deliveryMan: 'مندوب التوصيل',
    district: 'حي',
    Saturday: 'السبت',
    Sunday: 'الأحد',
    Monday: 'الإثنين',
    Tuesday: 'التثلاء',
    Wednesday: 'الأربعاء',
    Thursday: 'الخميس',
    Friday: 'الجمعة',
    discount: 'الخصم',
    discountPercentage: 'نسبة الخصم',
    discountValue: 'قيمة الخصم',
    discountType: 'نوع الخصم',
    finalPrice: 'السعر النهائي',
    requiredSelection: 'إختيار إجباري',
    sideDishes: 'الأصناف الجانبية',
    sideDish: 'صنف جانبي',
    defaultSize: 'الحجم الافتراضي',
    pricing: 'السعر',
    delete: 'حذف',
    contactUs: 'رسائل التواصل',
    reply: 'الرد على الرسالة',
    message: 'الرسالة',
    replied: 'تم الرد',
    singleSelection: 'اختيار واحد',
    multipleSelection: 'إختيار متعدد',
    selectionType: 'نوع الإختيار',
    primarySize: 'الإختيار الإفتراضي',
    extraPrice: 'سعر إضافي',
    general: 'عام',
    mealOptions: 'أصناف جانبية',
    sideDishGroup: 'جروب الصنف الجانبي',
    sideDishHelp: 'في حالة إختيار الوجبة على انها صنف جانبي لن يتم عرضها ضمن الوجبات في الموقع او التطبيق',
    none: 'لا يوجد',
    fixed: 'قيمة ثابتة',
    percentage: 'نسبة',

    // reports 
    reports: 'التقارير',
    salesReports: 'تقارير المبيعات',
    ordersReports: 'تقارير الطلبات',
    mealsReports: 'تقارير الوجبات',
    categoriesReports: 'تقارير العملاء',
    customersReports: 'تقارير الأقسام',

    // months 
    January: 'يناير',
    February: 'فبراير',
    March: 'مارس',
    April: 'إبريل',
    May: 'مايو',
    June: 'يونيه',
    July: 'يولو',
    August: 'أغسطس',
    September: 'سبتمر',
    October: 'أكتوبر',
    November: 'نوفمبر',
    December: 'ديسمبر',

    // weeks 
    firstWeek: 'الإسبوع الأول',
    secondWeek: 'الإسبوع الثاني',
    thirdWeek: 'الإسبوع الثالث',
    fourthWeek: 'الإسبوع الرابع',
    fifthWeek: 'الإسبوع الخامس',
});