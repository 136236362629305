import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { hasInitialLocaleCode } from 'reactor/router/navigator';
import { getCurrentLocaleCode } from 'reactor/localization/locales';
import { getCurrentBseAppPath } from 'reactor/router/apps-list';
import { concatRoute } from 'reactor/router';

const Link = React.forwardRef(function (props, forwardedRef) {
    let { to, href, localeCode, color, style = {}, relative, baseApp = getCurrentBseAppPath(), ...otherLinkProps } = props;

    if (!to && href) {
        to = href;
    }

    // Apply color if provided
    if (color) {
        style.color = color;
    }

    otherLinkProps.style = style;

    // Set rel attribute if target is set
    if (otherLinkProps.target) {
        otherLinkProps.rel = 'noopener noreferrer';
    }

    // If not relative, render an anchor tag
    if (!relative) {
        return <a href={to} ref={forwardedRef} {...otherLinkProps} />
    }

    // Handle locale code and path concatenation
    if (!localeCode && hasInitialLocaleCode()) {
        localeCode = getCurrentLocaleCode();
    }

    let path = concatRoute(baseApp, to);

    if (localeCode) {
        path = concatRoute(localeCode, path);
    }

    otherLinkProps.to = concatRoute(path);

    return <RouterLink {...otherLinkProps} ref={forwardedRef} style={style} />
});

Link.defaultProps = {
    relative: true,
};

export default Link;
