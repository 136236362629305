import React, { useEffect, useRef } from 'react';
import Link from "reactor/components/Link";
import '../assets/style/Menu.scss';
import './../assets/style/HeaderMenu.scss';
import { BASE_URL } from 'reactor/router/router-history';
export const menuLinks = [
    {
        title: 'عن رواد',
        children: [
            {
                title: "من نحن",
                hrefInner: "#about__section"
            },
            {
                title: "اتفاقية الخدمة",
                href: "/rowaad-tos"
            },
            {
                title: "سياسة الخصوصية ",
                href: "/rowaad-privacy"
            },
            {
                title: "طرق الدفع والحسابات",
                href: "/rowaad-payments"
            }
        ]
    },
    {
        title: 'خدماتنا',
        children: [
            {
                title: "تصميم تطبيقات الجوال",
                href: "/apps"
            },
            // {
            //     title: "تصميم متجر الكتروني",
            //     hrefOut: "https://products.rh.net.sa/store/web/"
            // },
            // {
            //     title: "تطبيق متجر الكتروني",
            //     hrefOut: "https://products.rh.net.sa/store/app/"
            // },
            {
                title: "تصميم المواقع الإلكترونية",
                href: "/webdesign"
            },
            {
                title: "تصميم اكاديمية عن بعد",
                href: "/acadimies"
            },
            {
                title: "قوالب مواقع جاھزة",
                href: "/templates"
            },
            {
                title: "إستضافة مشتركة",
                href: "/sharedhosting"
            },
            {
                title: "خوادم كاملة",
                href: "/dedicatedservers"
            },
            {
                title: "خوادم مشتركة",
                href: "/vps"
            }
        ]
    },
    {
        title: 'منتجاتنا',
        children: [
            {
                title: "متجر إلكتروني",
                hrefOut: "https://products.rh.net.sa/store/web/"
            },
            {
                title: "تطبيق متجر إلكتروني",
                hrefOut: "https://products.rh.net.sa/store/app/"
            },
            {
                title: "قريبا تطبيق لحجز المواعيد",
                href: "#"
            }
        ]
    },
    {
        title: 'أعمالنا',
        hrefOut: 'https://works.rh.net.sa/'
    },
    {
        title: 'المدونة',
        hrefOut: 'https://blog.rh.net.sa/'
    },
    {
        title: 'اتصل بنا',
        href: '/contact-us'
    },
];

export const otherLinks = [
    {
        title: 'تسجيل الدخول',
        href: 'https://my.rh.net.sa/index.php?rp=/login'
    },
    // {
    //     title: 'فتح التدكرة',
    //     href: 'https://my.rh.net.sa/submitticket.php?step=2&deptid=26'
    // },

];

export default function HeaderMenu({scrollToSection}) {


    let animeProps = {
        opacity: [0,1],
        translateY: [64,0],
        delay: (el,i) => i * 200,
        duration: 600,
    }

    return (
       <nav className='navbar'>
            <ul className='right-menu list-unstyled'>
                {
                    menuLinks.map((menuItem, index) => {
                        return (
                            <li key={index + "__"} className='nav-item'>{ menuItem.hrefOut ? <a href={menuItem.hrefOut} className="nav-link" target="_blank">{menuItem.title}</a> : menuItem.children ? <span className="nav-link">{menuItem.title}</span> : <Link href={menuItem.href} className="nav-link">{menuItem.title}</Link>}
                                {menuItem.children && (
                                <div className='dropdown-wrapper'>
                                    <ul className="dropdown-menu list-unstyled">
                                        {menuItem.children.map((link, index) => (
                                            <li key={"00" + index}> { link.hrefOut ? <a href={link.hrefOut} target="_blank">{link.title}</a> : link.hrefInner ? <a onClick={scrollToSection}>{link.title}</a> :<Link to ={link.href} key={index}>{link.title}</Link> } </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </li>
                        )
                    })
                }
            </ul>

            <ul className='list-unstyled other-links'>
                {
                    otherLinks.map((link, index) => {
                        return (
                            <li className='nav-item' key={index}><a href={link.href} target="_blank" className="nav-link">{link.title}</a></li>
                        )
                    })
                }
            </ul>
       </nav>
    )
}