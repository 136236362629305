import React, { useState, useEffect, useRef } from "react";
import Link from "reactor/components/Link";
import Hamburger from "./Hamburger";
import Container from 'reactor/components/Grid/ContainerWithWidth'
import './../assets/style/Header2.scss';
import Logo from './../components/Logo'
import LogoWhite from './LogoWhite'
import { makeStyles } from '@material-ui/core';
import gsap from 'gsap'
import HeaderMenu from "./HeaderMenu";
import MobileMenu from "./MobileHeader";

export default function Header(props) {
    const[ openMenu , setOpen ] = React.useState(false);
    const[ componentName , setComponentName ] = React.useState();

    useEffect(() => {
        const header = document.getElementById('header');
        const sticky = header.offsetTop;
    
        const handleScroll = () => {
          if (window.pageYOffset > sticky) {
            header.classList.add('sticky');
          } else {
            header.classList.remove('sticky');
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        // Cleanup function to remove the event listener
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    const useStyles = makeStyles(theme => ({
        headerStyle: {
            // background: props.dark ? props.background : '#fff'
        },
        linkStyle: {
            color: props.dark ? '#fff' : '#000'
        },
        dotSpan: {
            background: props.dark ? '#fff' : '#000'
        },
        breadcrumbSkew: {
            width: '2rem',
            height: '70rem',
            position: 'absolute',
            top: '0',
            left: '0',
            backgroundColor: props.dark ? '#fff' : '#000',
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 100% 100%)',
            zIndex: '1'
        }
    }));

    const classes = useStyles();
    const logoRef = useRef(null);

    // State of our Menu
    const [state, setState] = useState({
        initial: false,
        clicked: null,
        menuName: "القائمة"
    });
    // State of our button
    const [disabled, setDisabled] = useState(false);

    //Use Effect
    useEffect(() => {
        //Listening for page changes.
        setState({ clicked: false, menuName: "القائمة" });
    } , []);

    // Toggle menu
    const handleMenu = () => {
        disableMenu();
        if (state.initial === false) {
            setState({
                initial: null,
                clicked: true,
                menuName: "رجوع",
                class: 'close'
            });
        } else if (state.clicked === true) {
            setState({
                clicked: !state.clicked,
                menuName: "القائمة",
                class: 'menu'

            });
            gsap.to( logoRef.current, 1, {
                opacity: 1,
                y: 0,
                ease: 'power4.out',
            });
        } else if (state.clicked === false) {
            setState({
                clicked: !state.clicked,
                menuName: "رجوع",
                class: 'close'
            });
            gsap.to( logoRef.current, 1, {
                opacity: 0,
                y: 0,
                ease: 'power4.out',
            });
        }
    };


    //Determine if out menu button should be disabled
    const disableMenu = () => {
        setDisabled(!disabled);
        setTimeout(() => {
            setDisabled(false);
        }, 1200);
    };

  return (
    <header id="header">
        <Container block={"true"}>
            <div className={classes.breadcrumbSkew}></div>

            <div className="wrapper">
                <div className="inner-header">
                    <div ref={logoRef} className="logo">
                        <Link href="/">
                            {/* {props.dark ? <LogoWhite /> : <Logo />  } */}
                            <Logo />
                        </Link>
                    </div>
                    <div className="desktop-menu w-full">
                        <HeaderMenu scrollToSection={props.scrollToSection}/>
                    </div>
                    <div className={`menu mobile-menu ${state.class}`}>
                    <MobileMenu/>
                    </div>
                </div>
            </div>
        </Container>
        <Hamburger state={state} props />
    </header>
  );
};

