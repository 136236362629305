import WhatsAppIcon from '@material-ui/icons//WhatsApp';
import React from 'react';

export default function Whatsapp() {
  return (
    <>
      <a href="https://api.whatsapp.com/message/J5TXKF4PJY7LN1?autoload=1&app_absent=0" target="_blank" className="fixed-whatsapp" >
        <WhatsAppIcon/>
      </a>
    </>
  );
}