import React, { useEffect } from 'react';

export default function Helmet({ title, id, image, url, canonicalUrl, appendAppName = true, description, bodyClass }) {
    useEffect(() => {
        const trans = (text) => {
            // Your translation logic here
            return text;
        };

        const setMetaTag = (tagName, content) => {
            const existingTag = document.querySelector(`meta[name="${tagName}"]`);
            if (existingTag) {
                existingTag.setAttribute('content', content);
            } else {
                const metaTag = document.createElement('meta');
                metaTag.setAttribute('name', tagName);
                metaTag.setAttribute('content', content);
                document.head.appendChild(metaTag);
            }
        };

        const setTitle = (title) => {
            document.title = title;
            setMetaTag('og:title', title);
            setMetaTag('twitter:title', title);
        };

        const setDescription = (description) => {
            setMetaTag('description', description);
            setMetaTag('og:description', description);
            setMetaTag('twitter:description', description);
        };

        const setImage = (image) => {
            setMetaTag('og:image', image);
            setMetaTag('twitter:image', image);
        };

        const setUrl = (url) => {
            setMetaTag('og:url', url);
        };

        const setCanonicalUrl = (canonicalUrl) => {
            setMetaTag('canonical', canonicalUrl);
        };

        setTitle(trans(title));

        if (description) {
            setDescription(description);
        }

        if (image) {
            setImage(image);
        }

        if (url) {
            if (url === true) {
                url = window.location.href;
            }
            setUrl(url);
        }

        if (!canonicalUrl && url) {
            canonicalUrl = url;
        }

        if (canonicalUrl) {
            setCanonicalUrl(canonicalUrl);
        }

        if (bodyClass) {
            document.body.className = bodyClass;
        }

        if (id) {
            document.body.id = id;
        }

    }, [title, id, image, url, canonicalUrl, appendAppName, description, bodyClass]);

    return null;
}
