import React from 'react'
import Helmet from 'reactor/components/Helmet'
import './404Page.scss'
import { Container } from '@material-ui/core'
import Layout from '../../../components/Layout'

export default function NotFoundPage() {
    return(
        <Layout>
            <Helmet bodyClass="notfound" title="404"/>
            <Container>
                <h2>صفحة الخطأ </h2>
                <p class="main">404</p>
            </Container>
        </Layout>    
    )
}
