import CallIcon from '@material-ui/icons/Call';
import React from 'react';

export default function FixedContactButton() {
  return (
    <>
      <a href="tel:+966 11 512 1329" target="_blank" className="fixed-whatsapp" >
        <CallIcon/>
      </a>
    </>
  );
}