import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    headingTitle: {
        fontWeight: '900',
        fontSize: '40px',
        lineHeight: '1.8',
        marginBottom: '2rem',
        '@media (max-width: 1400px)': {
            fontSize: '36px !important',
        },
        '@media (max-width: 1200px)': {
            fontSize: '32px !important',
        },
        '@media (max-width: 992px)': {
            fontSize: '28px !important',
        },
        '@media (max-width: 768px)': {
            fontSize: '20px !important',
        },
        '@media (max-width: 576px)': {
            fontSize: '18px !important',
        },
    },
    
    headingSubtitle: {
        fontWeight: '400',
        fontSize: '20px',
        // color: '#000',
        marginBottom: '2rem',
        textAlign: "justify",
        lineHeight: "1.8",
        '@media (max-width: 1400px)': {
            fontSize: '18px',
        },
        '@media (max-width: 1200px)': {
            fontSize: '16px',
        },
        '@media (max-width: 992px)': {
            fontSize: '14px',
        },
        '@media (max-width: 768px)': {
            fontSize: '12px',
        },
    },
    notify: {
        fontWeight: '300',
        fontSize: '20px',
        textAlign: 'justify',
        '@media (max-width: 1400px)': {
            fontSize: '18px',
        },
        '@media (max-width: 1200px)': {
            fontSize: '17px',
        },
        '@media (max-width: 992px)': {
            fontSize: '16px',
        },
        '@media (max-width: 768px)': {
            fontSize: '15px',
        },
        '@media (max-width: 576px)': {
            fontSize: '14px',
        },
    }
    
    
}));


export default function Heading(props) {
    const classes = useStyles();

    return (
        <div className="section__heading">
            {props.title && <h3 className={classes.headingTitle} dangerouslySetInnerHTML={{__html: props.title}}></h3>}
            {props.subtitle && <p className={`lightFont__smallSize ${classes.headingSubtitle}`} dangerouslySetInnerHTML={{__html: props.subtitle}}></p>}
            {props.link && <p className={`lightFont__smallSize ${classes.headingSubtitle}`}>{props.link}</p>}
            {props.notify && <span className={classes.notify} dangerouslySetInnerHTML={{__html: props.notify}}></span>}
        </div>
    )
}