import React from 'react';
import Container from './GridContainer';
import { makeStyles } from '@material-ui/core';


export default function ContainerWithWidth(props) {

    const useStyles = makeStyles(theme => ({
        contentBody: {
            padding: '0 3rem',
            display: props.block ? 'block' : 'flex',
            maxWidth: '1400px',
            [theme.breakpoints.down('sm')]: {
                width: 'auto',
                // display: 'block',
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: '1200',
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: '1400px',
            },
            [theme.breakpoints.between('1900','2000')]: {
                maxWidth: '1700px',
            },
            [theme.breakpoints.between('1900','2000')]: {
                maxWidth: '1700px',
            },
            [theme.breakpoints.up('2000')]: {
                maxWidth: '1900px',
            },
            // [theme.breakpoints.down('991')]: {
            //     maxWidth: '720px',
            // },
            // [theme.breakpoints.down('1190')]: {
            //     maxWidth: '900px',
            // },
            // [theme.breakpoints.between('999' , '1190')]: {
            //     maxWidth: '800px',
            // },
            // // [theme.breakpoints.up('1024')]: {
            // //     maxWidth: '1460px',
            // // },
            // [theme.breakpoints.up('1367')]: {
            //     maxWidth: '1760px',
            // },
            // // [theme.breakpoints.up('1920')]: {
            // //     maxWidth: '1860px',
            // // },
            // [theme.breakpoints.up('3000')]: {
            //     maxWidth: '2860px',
            // },
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    }));
    const classes = useStyles();

    return <Container {...props} className={classes.contentBody}></Container>
}