import React from 'react'
import logo from "assets/images/logo.png";

export default function Logo(props) {
    return (
        // <svg width="315.659" height="85.883" viewBox="0 0 315.659 85.883">
        //     <g id="Group_6491" data-name="Group 6491" transform="translate(-1471 -57)">
        //         <path id="Path_1" data-name="Path 1" d="M644.36,764.6l13.019-8.332V693.78L644.36,706.8Z" transform="translate(1064.187 -636.78)" fill={props.color} />
        //         <path id="Path_2" data-name="Path 2" d="M469.94,837.785,482.959,850.8h18.594l-7.561,7.561-.076-.076H477.361L475,855.925l-.625.526,14.857,14.857h17.706V854.957l-16.686-17.172Z" transform="translate(1194.061 -744.007)" fill={props.color} />
        //         <path id="Path_3" data-name="Path 3" d="M879.791,788.359v43.346l-6.669,6.669,2.018,2.018.743-.743,0,0,16.924-16.925V775.34Z" transform="translate(893.85 -697.51)" fill={props.color}  />
        //         <path id="Path_4" data-name="Path 4" d="M725.574,777.542v49.17h1.39l20.106-15.461-.318.318v22.523l-5.924,5.924,1.445,1.445.573.573,16.925-16.925V787.655l-32.807-9.764Zm1.39,36.151v-21.7l19.789,8.681Z" transform="translate(1003.714 -699.149)" fill={props.color} />
        //         <text id="paht_5" data-name="رواد في صناعة الويب" transform="translate(1652 101)" fill={props.color} font-size="21" font-weight="500"><tspan x="-40.614" y="0"></tspan><tspan y="0">رواد</tspan><tspan x="-180.159" y="25">في صناعة الويب</tspan></text>
        //     </g>
        // </svg>
        <img alt="رواد" src={logo} className="img-logo" />
    )
}