import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { menuLinks, otherLinks } from './HeaderMenu';
import Link from 'reactor/components/Link';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function MobileMenu(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleCloseSidebar = () => {
    setMobileOpen(false);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List className='main-links'>
        {menuLinks.map((menuItem, index) => (
          <li className='nav-item' key={index + "_"}>
            {menuItem.hrefOut ? (
              <a href={menuItem.hrefOut} target="_blank" className='nav-link'>{menuItem.title}</a>
            ) : menuItem.children ? (
              <span className="nav-link">{menuItem.title}</span>
            ) : (
              <Link to={menuItem.href} className="nav-link" onClick={handleCloseSidebar}>{menuItem.title}</Link>
            )}
            {menuItem.children && (
              <div className='dropdown-wrapper'>
                <ul className="dropdown-menu list-unstyled">
                  {menuItem.children.map((link, index) => (
                    <li key={0 + index}>
                      {link.hrefOut ? (
                        <a href={link.hrefOut} target="_blank">{link.title}</a>
                      ) : link.hrefInner ? (
                        <a href={link.hrefInner}>{link.title}</a>
                      ) : (
                        <Link to={link.href} key={index} onClick={handleCloseSidebar}>{link.title}</Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        ))}
      </List>
      <List className='other-links'>
        {otherLinks.map((link, index) => (
          <li className='nav-item' key={index}>
            <a href={link.href} target="_blank" className="nav-link">{link.title}</a>
          </li>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <svg width="30" height="21" viewBox="0 0 37.81 28.769">
                            <defs>
                                <clipPath id="clip-path">
                                    <rect width="37.81" height="28.769" fill="none"/>
                                </clipPath>
                                </defs>
                                <g id="Symbol_1_1" data-name="Symbol 1 – 1" clipPath="url(#clip-path)">
                                <path fill='#000' id="Path_5" data-name="Path 5" d="M1171.432,3585.614a1.818,1.818,0,0,1,.22-2.568c6.631-5.567,13.4-3.616,19.155-.974,6.067,2.788,10.977,2.524,15.008-.8a1.825,1.825,0,1,1,2.335,2.806,14.859,14.859,0,0,1-9.7,3.557,22.073,22.073,0,0,1-9.178-2.25c-5.712-2.624-10.493-3.551-15.264.452A1.835,1.835,0,0,1,1171.432,3585.614Zm34.382,5.945c-4.031,3.329-8.941,3.592-15.008.8-5.75-2.642-12.523-4.592-19.155.974a1.826,1.826,0,1,0,2.358,2.788c4.77-4,9.552-3.076,15.264-.452a22.1,22.1,0,0,0,9.178,2.25,14.856,14.856,0,0,0,9.7-3.557,1.825,1.825,0,1,0-2.335-2.806Zm0,10.316c-4.031,3.329-8.941,3.592-15.008.8-5.75-2.642-12.523-4.593-19.155.974a1.825,1.825,0,1,0,2.358,2.787c4.77-4,9.552-3.077,15.264-.452a22.1,22.1,0,0,0,9.177,2.249,14.857,14.857,0,0,0,9.7-3.557,1.825,1.825,0,1,0-2.335-2.806Z" transform="translate(-1171.001 -3579.469)"/>
                                </g>
                            </svg>
        </IconButton>
      </Toolbar>
      <Drawer
        container={container}
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        className='toggle-btn'
      >
        {drawer}
      </Drawer>
    </div>
  );
}

MobileMenu.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default MobileMenu;
