import React from 'react';
import { styled } from '@material-ui/core';
import faXTwitter from "assets/images/xtwitter.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBehance, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'

const social = [
    // {
    //     href: "",
    //     icon: faGithub,
    //     label: "Github",
    //     color: "#333"
    // },
    {
        href: "https://behance.net/rowaadsa",
        icon: faBehance,
        label: "Behance",
        color: "#1769ff"
    },
    {
        href: "https://instagram.com/rowaad_sa",
        icon: faInstagram,
        label: "Instagram",
        color: "#e1306c"
    },
    {
        href: "https://youtube.com/@rhnetsa",
        icon: faYoutube,
        label: "Youtube",
        color: "#ff0000"
    },
    {
        href: "https://x.com/Rowaad_SA",
        image: faXTwitter,
        label: "Twitter",
        color: "#1da1f2"
    },
    // {
    //     href: "",
    //     icon: faLinkedinIn,
    //     label: "LinkedinIn",
    //     color: "#0077b5"
    // },
    // {
    //     href: "",
    //     icon: faPinterestP,
    //     label: "Pinterest",
    //     color: "#e60023"
    // },
    // {
    //     href: "",
    //     icon: faFacebookF,
    //     label: "facebook",
    //     color: "#3b5998"
    // },
]


const SocialLink = styled('li')({
    fontSize: '32px',
    '& a': {
        transition: 'all 0.5s ease-in-out',
    },
    '@media (max-width: 1200px)': {
        fontSize: '5rem',
    },
    '@media (max-width: 768px)': {
        fontSize: '8rem',
    },
});

export default function Social() {

    const mainColor = '#000';
    const updateColor = (e, color) => {
        e.target.style.color = color
    }

    return(
        <div className="social">
            <ul className="list-inline social__links">
                {social.map( (socialLink, index) => (
                    <SocialLink key={index}>
                        <a
                        aria-label={socialLink.label}
                        onMouseEnter={(e) => updateColor(e, socialLink.color)}
                        onMouseLeave={(e) => updateColor(e, mainColor)}
                        className="menuLink" href={socialLink.href}>
                            {
                                socialLink.icon ? <FontAwesomeIcon icon={socialLink.icon} /> : <img src={socialLink.image} alt={socialLink.label} />
                            }
                        </a>
                    </SocialLink>
                ))}
            </ul>
        </div>
    )
}