import React, { Suspense, useRef } from 'react'
import 'assets/style/General.scss'
// import Header from './Header';
import Header2 from './Header2'
import Footer from './Footer'
import { Obj } from 'reinforcements'
import { getSettings } from '../services/misc'
import SettingsContext from '../context/SettingsContext'
import MainLayout from 'reactor/layout/components/Layout'
import HelpSection from './../home/components/help/HelpSection'
import Numbers from './../home/components/Numbers'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { TweenMax, TimelineMax, Power3, Power4 } from "gsap";
// import '@mantine/notifications/styles.css';
// import { MantineProvider } from '@mantine/core';
// import { Notifications } from '@mantine/notifications';

export default function Layout(props )
{
    const sectionRef = useRef(null);

    const scrollToSection = () => {
      if (sectionRef.current) {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      console.log("test");
    };
    const [settingsData, setSettings] = React.useState();

    function getSetting(key, defaultValue)
    {
        return Obj.get(settingsData, key, defaultValue);
    }

    React.useEffect(() =>
    {
        getSettings().then(response =>
        {
            const { settings } = response.data;

            setSettings(settings);
        });
    }, []);

    const Loading = () => <div>Loading...</div>;


    return (
        <Suspense fallback={<Loading />}>
            <SettingsContext.Provider value={getSetting}>
                <MainLayout>
                    <Header2 scrollToSection={scrollToSection}/>
                    <main children={props.children}/>
                    <Footer />
                </MainLayout>
            </SettingsContext.Provider>
        </Suspense>
    )
}
