import React from 'react';
import './Numbers.scss';
import Container from 'reactor/components/Grid/ContainerWithWidth'
import GridItem from 'reactor/components/Grid/GridItem'
import BoxItem from 'reactor/components/Box'
import Slider from "react-slick"
import Heading from './../../components/heading'


const sliderContent = [
    {
        title: "عام فى السوق السعودي",
        number: "+12",
    },
    {
        title: "خدمة تم تقديمها",
        number: "+100K",
    },
    {
        title: "عميل حول المملكة",
        number: "+10K",
    },
    {
        title: "سطر برمجي تم كتابته",
        number: "+10M",
    },
]

export default function Numbers() {
    return (
        <div className="numbers">
            <Container block={"true"}>
                <Heading title="ارقام النجاح"/>
                <BoxItem className="numbers__slider" display="flex" justifyContent="space-between" alignItems="center">
                    {sliderContent.map( (item, index)=> (
                        <GridItem sm={3} xs={12} key={index} className="numbers__item" py={4} my={2}>
                            <h3 className="numbers__item--number">{item.number}</h3>
                            <p className="numbers__item--title">{item.title}</p>
                        </GridItem>
                    ))}
                </BoxItem>
            </Container>
        </div>
    )
}
